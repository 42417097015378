export const whyMeConstants = {
  title: "POURQUOI ME CHOISIR ?",
  subtitle: `Je m’appelle Laurie Escobedo, j’ai 34 ans et je suis passionnée de
  langue et de littérature. J’essaie de transmettre cette passion à
  mes élèves. Pourquoi me choisir ?`,
  listOne: `👉 Professeure qualifiée : certifiée en lettres modernes et
  titulaire d’un MASTER d’enseignement dans cette discipline.`,
  listTwo: `👉 Professeure expérimentée : 10 ans d’enseignement avec des
  collégiens et des lycéens. Connaissance et maîtrise du programme
  de la sixième à la terminale.`,
  listThree: `👉 Professeure spécialisée dans les cours particuliers : mise en
  place d'un espace de confiance avec l'élève afin que le cadre de
  travail soit propice aux apprentissages.`,
  listFour: `👉 Professeure créative : toujours à la recherche d’idées pour
  motiver et intéresser les élèves.`,
  listFive: `👉 Professeure à l’écoute des besoins et des difficultés :
  adaptation en fonction des différents élèves, prise en compte de
  leurs difficultés et des troubles existants (dyslexie,
  dysorthographie, trouble de déficit de l'attention, ...).`,
};