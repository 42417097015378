import React from "react";
import "./ma-method.scss";

const MaMethod = () => {
  return (
    <div className="ma-method">
      <div
        className="title-section text-left text-sm-center"
        data-aos="fade-up"
        data-aos-duration="1200"
      >
        <div>
          <p className="text-uppercase custom-title mb-0 ft-wt-600 pb-3 text-center">
            ⚡️⚡️⚡️⚡️
          </p>
        </div>
        <div>
          <h2 className="text-uppercase custom-title mb-0 ft-wt-600 pb-3 text-center">
            MA MÉTHODE POUR RÉUSSIR
          </h2>
        </div>
        <div>
          <p className="text-uppercase custom-title mb-0 ft-wt-600 pb-3">
            ⚡️⚡️⚡️⚡️
          </p>
        </div>
      </div>

      <div className="container" data-aos="fade-up" data-aos-duration="1200">
        <div className="row mb-20">
          <div class="col-12 col-lg-12">
            <p class="roboto-font mb-4">
              Forte de mon expérience sur le terrain, je pratique aujourd’hui ma
              propre méthode visant à remettre l’élève au centre des
              apprentissages en créant un espace de confiance, propice à
              l’amélioration. L’objectif est avant tout que les élèves se
              sentent épaulés et soutenus, qu’ils retrouvent l’envie et le désir
              d’apprendre en n’ayant plus peur de l’échec.
            </p>
          </div>

          <div class="col-12 col-lg-12">
            <h3 class="text-uppercase custom-title mb-0 ft-wt-600 pb-3 text-center">
              💥 Étape 1 : DIAGNOSTIC 💥
            </h3>
            <p class="roboto-font mb-4">
              Cette étape peut s'effectuer en plusieurs temps. D'abord, il est
              essentiel d'avoir un premier échange (par téléphone ou en
              visioconférence) avec l'élève ou avec ses parents dans le but de
              connaître les demandes. J’exposerai également ma façon de
              travailler plus en détails. Ensuite, le premier cours permet d'en
              savoir plus sur le profil de l’élève, tout en découvrant ses
              besoins et ses difficultés.
            </p>
          </div>

          <div class="col-12 col-lg-12">
            <h3 class="text-uppercase custom-title mb-0 ft-wt-600 pb-3 text-center">
              💥 Étape 2 : PLAN D’ACTION 💥
            </h3>
            <p class="roboto-font mb-4">
              Il est défini selon les objectifs visés. Il peut s’agir de :
            </p>
            <div>
              - Cours de soutien en français de la 6e à la 1ère (revoir les
              leçons et exercices effectués en classe).
            </div>
            <div>
              - Cours de français général pour revoir les bases, améliorer son
              orthographe et sa rédaction (grammaire, orthographe, conjugaison,
              vocabulaire, rédaction, lecture, compréhension de texte).
            </div>
            <div>
              - Préparation des épreuves anticipées de français (baccalauréat
              général, technologique et professionnel).
            </div>
            <div>- Préparation du baccalauréat.</div>
            <div>👉 Méthodologie des épreuves.</div>
            <div>👉 Entrainements variés.</div>
            <div>👉 Fiches contenant les éléments-clés du programme.</div>
            <div>👉 Révision des notions non maîtrisées.</div>
            <div>
              👉 Correction des évaluations effectuées en classe et aide aux
              devoirs.
            </div>
            <div>
              👉 Conseils stratégiques pour obtenir de meilleurs résultats.
            </div>
            <div>- Préparation du brevet des collèges.</div>
            <div>👉 Méthodologie de l’épreuve.</div>
            <div>👉 Entrainements variés.</div>
            <div>👉 Révision des notions non maîtrisées.</div>
            <div>
              👉 Correction des évaluations effectuées en classe et aide aux
              devoirs.
            </div>
            <div>
              👉 Conseils stratégiques pour obtenir de meilleurs résultats.
            </div>
            <div>
              - Cours de Français Langue Étrangère (uniquement en français ou en
              s’appuyant sur l’espagnol). Ce plan peut évoluer au fur et à
              mesure des séances.
            </div>
          </div>

          <div class="col-12 col-lg-12">
            <h3 class="text-uppercase custom-title mb-0 ft-wt-600 pb-3 pt-3 text-center">
              💥 Étape 3 : ÉLABORATION D’UNE PROGRESSION INDIVIDUALISÉE 💥
            </h3>
            <p class="roboto-font mb-4">
              Je prépare les cours en fonction de l’élève, des documents
              personnalisés seront donc envoyés. À la fin de chaque heure, un
              court bilan permettra de récapituler les points évoqués, les
              acquis, la progression, les ressentis et les questionnements de
              l’élève. N’hésitez pas à me contacter pour avoir un premier
              échange.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MaMethod;
