import React from "react";

const Address = () => {
  return (
    <>
      <p className="roboto-font custom-span-contact position-relative">
        <i className="fa fa-envelope-open position-absolute"></i>
        <span className="d-block">Envoyez-moi un e-mail</span>{" "}
        <a href="mailto:franjseco@gmail.com">escobedo.laurie.pro@gmail.com</a>
      </p>
    </>
  );
};

export default Address;
