import React, { useState } from "react";
import Temoinage from "./components/temoinage/Temoinage";
import Carousel from "react-bootstrap/Carousel";
import testimonies from "./components/temoinage/utils/constants/temoinages";
import "./temoinages.scss";

const Temoinages = ({ isMain }) => {
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };
  return (
    <div className="carousel-wrapper">
      {!isMain && (
        <div
          className="title-section text-left text-sm-center"
          data-aos="fade-up"
          data-aos-duration="1200"
        >
          <h2 className="text-uppercase custom-title mb-0 ft-wt-600 pb-3">
            ⚡️⚡️⚡️⚡️ TEMOINAGE ⚡️⚡️⚡️⚡️
          </h2>
        </div>
      )}

      <Carousel variant="dark" activeIndex={index} onSelect={handleSelect}>
        {testimonies.map(({ temoinage, autor, index }) => (
          <Carousel.Item key={index}>
            <Temoinage temoinage={temoinage} autor={autor} />
          </Carousel.Item>
        ))}
      </Carousel>
    </div>
  );
};

export default Temoinages;
