import React from "react";
import PersonalInfo from "./components/PersonalInfo";
import Achievements from "./components/Achievements";
import Experience from "./components/Experience";
import Education from "./components/Education";
import { WhyMe } from "./components/whyMe";
import "./about.scss";
const About = () => {
  return (
    <div data-aos="fade-up" data-aos-duration="1200">
      <div className="title-section text-left text-sm-center">
        <h1>
          À propos de <span>moi</span>
        </h1>
      </div>
      <section className="main-content">
        <div className="container">
          <div className="row">
            {/* Personal Info Starts */}

            <div className="col-xl-6 col-lg-5 col-12">
              <div className="row">
                <div className="col-12">
                  <h3 className="text-uppercase custom-title mb-0 ft-wt-600">
                    Informations personnelles
                  </h3>
                </div>

                <div className="col-12">
                  <PersonalInfo />
                </div>
                {/* End personal info */}

                {/* End download button */}
              </div>
            </div>
            {/*  Personal Info Ends */}

            {/*  Boxes Starts */}
            <div className="col-xl-6 col-lg-7 col-12 mt-5 mt-lg-0">
              <Achievements />
            </div>
            {/* Achievements Ends */}
          </div>
          {/* End .row */}
        </div>
        <hr className="separator" />
        <div className="container">
          <WhyMe />
          {/* Experience & Education Starts */}
          <div className="row">
            <div className="col-12">
              <h3 className="text-uppercase pb-5 mb-0 text-left text-sm-center custom-title ft-wt-600">
                Expériences <span>&</span> Formations
              </h3>
            </div>
            <div className="col-lg-6">
              <div className="resume-box">
                <Experience />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="resume-box">
                <Education />
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default About;
