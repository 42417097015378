import React from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import Hero from "../../components/hero/Hero";
import About from "../../components/about/About";
import Contact from "../../components/contact/Contact";
import MaMethod from "../../components/maMethod/MaMethod";
import { menuItem } from "./utils/constants/menuItemConstants";

const Home = () => {
  return (
    <div className="secondaryColor">
      <Tabs>
        <div className="header">
          <TabList className="icon-menu revealator-slideup revealator-once revealator-delay1">
            {menuItem.map((item, index) => (
              <Tab className="icon-box" key={index}>
                <i className={`fa ${item.icon}`}></i>
                <h2>{item.menuName}</h2>
              </Tab>
            ))}
          </TabList>
        </div>

        <div className="tab-panel_list">
          <TabPanel className="home">
            <Hero />
          </TabPanel>
          <TabPanel className="maMethod">
            <MaMethod />
          </TabPanel>
          <TabPanel className="about">
            <About />
          </TabPanel>
          <TabPanel className="contact">
            <Contact />
          </TabPanel>
        </div>
      </Tabs>
    </div>
  );
};

export default Home;
