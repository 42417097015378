export const experienceContent = [
  {
    year: "À partir de 2020",
    position: "Professeure de français",
    details: `Autoentrepreneuse.`,
  },
  {
    year: "2013-2020",
    position: "Professeure de français dans des collèges et lycées publics",
    details: "Éducation Nationale.",
  },
];
