import React from "react";
import { useForm } from "react-hook-form";
import emailjs from "emailjs-com";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ContactForm = () => {
  const userId = process.env.REACT_APP_EMAILJS_USERID;
  const templateId = process.env.REACT_APP_EMAILJS_TEMPLATEID;
  const serviceId = process.env.REACT_APP_EMAILJS_SERVICEID;

  const handleNotifyOnSuccess = () =>
    toast.success("Message envoyé !", {
      position: "bottom-right",
    });

  const handleNotifyOnFail = () =>
    toast.error("Il y avait un problème ! Veuillez réessayer.", {
      position: "bottom-right",
    });
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (data, event) => {
    // console.log("Message submitted: " + JSON.stringify(data));
    event.preventDefault();
    emailjs.sendForm(serviceId, templateId, event.target, userId).then(
      (response) => {
        handleNotifyOnSuccess();
      },
      (err) => {
        handleNotifyOnFail();
      }
    );

    event.target.reset();
  };

  return (
    <>
      <form className="contactform" onSubmit={handleSubmit(onSubmit)}>
        <div className="row">
          <div className="col-12 col-md-6">
            <div className="form-group">
              <input
                {...register("name", { required: true })}
                type="text"
                name="name"
                placeholder="VOTRE NOM"
              />
              {errors.name && errors.name.type === "required" && (
                <span className="invalid-feedback">Le nom est requis</span>
              )}
            </div>
          </div>
          {/* End .col */}

          <div className="col-12 col-md-6">
            <div className="form-group">
              <input
                {...register(
                  "email",
                  {
                    required: "L'e-mail est requis",
                    pattern: {
                      value: /\S+@\S+\.\S+/,
                      message:
                        "La valeur saisie ne correspond pas au format de l'e-mail",
                    },
                  },
                  { required: true }
                )}
                type="email"
                name="email"
                placeholder="VOTRE E-MAIL"
              />
              {errors.email && (
                <span className="invalid-feedback">{errors.email.message}</span>
              )}
            </div>
          </div>
          {/* End .col */}

          <div className="col-12 col-md-12">
            <div className="form-group">
              <input
                {...register("subject", { required: true })}
                type="text"
                name="subject"
                placeholder="VOTRE SUJET"
              />
              {errors.subject && (
                <span className="invalid-feedback">Le sujet est requis.</span>
              )}
            </div>
          </div>
          {/* End .col */}

          <div className="col-12">
            <div className="form-group">
              <textarea
                {...register("message", { required: true })}
                name="message"
                placeholder="VOTRE MESSAGE"
              ></textarea>
              {errors.message && (
                <span className="invalid-feedback">Le message est requis.</span>
              )}
            </div>
          </div>
          {/* End .col */}

          <div className="col-12">
            <button type="submit" className="button">
              <span className="button-text">Envoyer le message</span>
              <span className="button-icon fa fa-send"></span>
            </button>
          </div>
          {/* End .col */}
        </div>
      </form>

      {/* End ContactForm */}
    </>
  );
};

export default ContactForm;
