const testimonies = [
  { temoinage: "Elle propose des leçons sur mesure, adaptées au niveau et aux besoins", autor: "Nassima (mère d’un élève de seconde)" },
  { temoinage: "Je n'avais jamais imaginé qu’apprendre le français pouvait être amusant jusqu'à ces cours !", autor: "Razane (élève de Français Langue Étrangère)" },
  { temoinage: "Les notes ont progressé et il s'est senti beaucoup plus en confiance.", autor: "Nelly (mère d’un élève de première)" },
  { temoinage: "Grâce à vos cours, j’ai pu voir ma note tripler : d’un 6 au bac blanc à un 17 au bac de français !", autor: "Elève de première" },
  { temoinage: "Elle a réussi à vraiment me faire comprendre comment faire de façon assez simple une bonne copie", autor: "Sami (élève de première)" },
  { temoinage: "Si j’avais connu Laurie avant, j’aurais eu un niveau supérieur à aujourd’hui !", autor: " Salvador (élève de Français Langue Étrangère)" },
  { temoinage: "Je viens de passer mon bac de français. Cela n'aurait jamais été possible sans Laurie !", autor: "Tristan (élève de première)" },
  { temoinage: "Ce n'est pas que des cours de grammaire ennuyeux ! C'est toute une expérience !", autor: "Razane (élève de Français Langue Étrangère)" },
  { temoinage: "Laurie est une professeure très compétente et pédagogue", autor: "Véronique (mère d’un élève de première)" },
  { temoinage: "Si vous avez besoin d'une prof pour vous accompagner et vous aider, vous êtes au bon endroit.", autor: "Élève de première" },
  { temoinage: "Tu vois les hyper bons profs, bah Laurie c’est un next level !", autor: "Élève de première" },
  { temoinage: `Super prof très sympa et qui explique très bien les cours. Laurie est très à l'écoute des difficultés de mon fils. Il se sent bien plus en confiance et plus à l'aise au lycée. Merci Laurie !`, autor: "Florence (mère d’un élève de première)" },
  { temoinage: `“Apprendre le français à partir de zéro avec vous a été une excellente expérience d'apprentissage, avec des cours dynamiques et des devoirs. J'ai réussi à obtenir un B1 en 6 mois, ça a été fantastique !!!”`, autor: "Dayana (élève de Français Langue Étrangère)" },
];

export default testimonies