import React from "react";
import Rating from "@mui/material/Rating";
import StarIcon from "@mui/icons-material/Star";
import "../../temoinages.scss";

const Temoinage = ({ temoinage, autor }) => {
  return (
    <div className="card border-0 mb-2 d-flex justify-content-center">
      <div className="card-body">
        <p className="card-body-author">{autor}</p>
        <p className="card-body-text">{temoinage}</p>
        <div className="d-flex justify-content-center mb-2">
          <Rating
            name="text-feedback"
            value={5}
            readOnly
            precision={1}
            emptyIcon={
              <StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />
            }
          />
        </div>
      </div>
    </div>
  );
};

export default Temoinage;
