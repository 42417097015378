import React from "react";
import { personalInfoContentConstants } from "../utils/constants/personalInfoContentConstants";

const PersonalInfo = () => {
  return (
    <ul className="about-list list-unstyled roboto-font">
      {personalInfoContentConstants.map((item, index) => (
        <li key={index} className="text-overflow-clip">
          <span className="title">{item.meta}: </span>
          <span className="value d-block d-sm-inline-block d-lg-block d-xl-inline-block">
            {item.metaInfo}
          </span>
        </li>
      ))}
    </ul>
  );
};

export default PersonalInfo;
