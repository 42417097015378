import React from "react";
import { whyMeConstants } from "../utils/constants/whyMeConstants";

export const WhyMe = () => {
  return (
    <div className="container mb-30">
      <div className="row">
        <div className="col-12 col-lg-12">
          <h3 className="text-uppercase custom-title mb-0 ft-wt-600 text-center">
            {whyMeConstants.title}
          </h3>
          <p className="roboto-font mb-4">{whyMeConstants.subtitle}</p>
        </div>
        <div className="col-12 col-lg-12">
          <ul className="roboto-font mb-4 whyme-list list-unstyled p-0 m-0">
            <li>{whyMeConstants.listOne}</li>
            <li>{whyMeConstants.listTwo}</li>
            <li>{whyMeConstants.listThree}</li>
            <li>{whyMeConstants.listFour}</li>
            <li>{whyMeConstants.listFive}</li>
          </ul>
        </div>
      </div>
    </div>
  );
};
