import React from "react";
import ContactForm from "./components/ContactForm";
import Address from "../Address";
import Social from "../Social";

const Contact = () => {
  return (
    <div className="contact">
      <div
        className="title-section text-left text-sm-center"
        data-aos="fade-up"
        data-aos-duration="1200"
      >
        <h1>
          Me <span>contacter</span>
        </h1>
      </div>
      <div className="container" data-aos="fade-up" data-aos-duration="1200">
        <div className="row">
          <div className="col-12 col-lg-4">
            <h3 className="text-uppercase custom-title mb-0 ft-wt-600 pb-3">
              Vous voulez plus d'informations ?
            </h3>
            <p className="roboto-font mb-4">
              N'hésitez pas à me contacter si vous avez des questions. Nous
              pourrions également convenir d'un premier rendez-vous par
              téléphone ou en visioconférence.
            </p>
            <Address />
            <Social />
          </div>
          <div className="col-12 col-lg-8">
            <ContactForm />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
