import React from "react";
import Social from "../Social";
import Temoinages from "../temoinages/Temoinages";
import { heroContent } from "./utils/constants/hereContentConstants";

const Hero = () => {
  return (
    <div
      className="container-fluid main-container container-home p-0 text-center"
      data-aos="fade-up"
      data-aos-duration="1200"
    >
      <div className="row home-details-container align-items-center">
        <div
          className="col-lg-4 bg position-fixed d-none d-lg-block"
          style={{
            backgroundImage: `url(${
              process.env.PUBLIC_URL + heroContent.heroImage
            })`,
          }}
        ></div>
        <div className="col-12 col-lg-8 offset-lg-4 home-details text-center text-lg-start">
          <div>
            <h1 className="title-hero text-uppercase poppins-font">
              {heroContent.heroTitleName}
              <span>{heroContent.heroDesignation}</span>
            </h1>
            <p className="roboto-font">{heroContent.heroDescriptions}</p>
            <p className="roboto-font">
              {heroContent.heroDescriptions2ndParagraph}
            </p>
            <div className="contact hero-social_front">
              <Social />
            </div>
            <div className="hero-temoinages_front">
              <Temoinages isMain />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;
