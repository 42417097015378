export const educationContent = [
  {
    year: "2013",
    degree: "CAPES de Lettres Modernes",
  },
  {
    year: "2013",
    degree: "Master de Lettres modernes et enseignement",
    institute: "Sorbonne",
  },
  {
    year: "2011",
    degree: "Licence de Lettres Modernes",
  },
];
